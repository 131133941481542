import { Component, OnInit, Input, SimpleChanges } from "@angular/core";
import { OnBoardingStatus } from "src/app/interfaces/supplier";
import { environment } from "src/environments/environment";
import * as moment from "moment";
import { ProcessStatus } from "src/app/interfaces/workflow";
import { SupplierStatus } from "src/app/interfaces/mapping";
import { LanguageService } from "../../../dynamic-components/utils/language.service";


@Component({
  selector: 'app-csns-onboard-workflow-circle',
  templateUrl: './csns-onboard-workflow-circle.component.html',
  styleUrl: './csns-onbaord-workflow-circle.component.sass'
})
export class OnboardCsnsWorkflowCircleComponent implements OnInit {
  steptitle: string="";
  stepInfo: string = "";
  circle: any;
  StepStatus = ProcessStatus;
  supplierStatus=SupplierStatus;
  circleColorClass: string = 'circle-todo';
  lineColorClass:string='line-todo';
  eStatus = OnBoardingStatus;
  dateIsvalid = true;
  lastStepsName:Array<string>=["Completed","Deactivated","Terminated"];
  isLast: boolean=false;
  showDate:Boolean=false;
  isShowWfCircleTooltip = false;
  comment: string = '';
  status: string = '';
  approverName: string = '';

  constructor(private languageService: LanguageService)
  {

  }


  @Input() set circleStep(value) {
    this.circle = value;
    this.steptitle = this.languageService.getValue("static.process.SupplierSetupSteps." + this.circle.StepTitle + ".title");
    if(this.circle.Status==this.eStatus.Rejected)
    {
    this.stepInfo = this.languageService.getValue("static.process.SupplierSetupSteps." + this.circle.StepTitle + ".reject_intro");
    }
    else
    {
    this.stepInfo = this.languageService.getValue("static.process.SupplierSetupSteps." + this.circle.StepTitle + ".intro");
    }


    // method to get  circle color
    this.GetCircleAndLineColorClass(this.circle);
    if(this.lastStepsName.indexOf(this.circle?.StepName)>=0)
      {
        this.isLast=true;
      }

    // Set values for tooltip
    this.comment = this.circle.Comments;
    this.status = this.circle.Status;
    this.approverName = this.circle.AssignedToEmail;

  }
  get circleStep(): string {
    return this.circle;
  }

  GetCircleAndLineColorClass(circle): void {
    if (circle.Status == this.StepStatus.completed) {
      this.circleColorClass = 'circle-done';
      this.lineColorClass='line-done';
      this.showDate=true;
    }
    else if (circle.Status == this.StepStatus.rejected) {
      this.circleColorClass = 'circle-rejected';
      this.lineColorClass='line-rejected';
      this.showDate=true;
    }
    else
    {
      this.circleColorClass = 'circle-todo';
      this.lineColorClass='line-todo';
      this.showDate=false;
    }
    if(circle.StepName == this.supplierStatus.deactivated ||circle.StepName == this.supplierStatus.terminated)
      {
         this.circleColorClass = 'circle-terminated';
      }
  }

  ngOnInit() {}
  getUpdateDate(date: any) {
    if (date !== undefined && date !== "") {
      if (moment(date).isValid()) {
        return moment(date).utc().toDate();
      } else {
        this.dateIsvalid = false;
        return date;
      }
    }
  }

  wfCircleMouseover() {
    this.isShowWfCircleTooltip = true;
  }
  wfCircleMouseleave() {
    this.isShowWfCircleTooltip = false;
  }

}
