import { Injectable, EventEmitter } from "@angular/core";
import { ActivatedRoute, Router } from "@angular/router";
import { SupplierService } from "src/app/services/supplier.service";
import { SupplierProfile } from "src/app/interfaces/supplierProfile";
import {
  OnBoardingRequestInfo,
  OnBoardingRequestInfoType,
} from "src/app/interfaces/buyer";
import { BuyerService } from "src/app/services/buyer.service";
import { DashBoardService } from "./dashboard.service";
import { SupplierModel,WorkFlowTransactionModel } from "src/app/interfaces/supplierModel";
import {
  SupplierMappingDTO,
  createSupplierMapping,
  SupplierPendingStatus,
  SupplierStatus,
  AP_ReviewStatus,
  RiskAssessmentStatus,
  StatusType,
  SpendCategoryGroup,
} from "src/app/interfaces/mapping";
import { AuthService } from "src/app/services/auth/auth.service";
import { BuyerChangeRequestDTO } from "src/app/interfaces/changeRequest";
import * as moment from "moment";
import {
  ProcessNames,
  SetupProcess,
  AllTaskKey,
  HistoryTask,
  Task,
  MigrateProcess,
} from "src/app/interfaces/workflow";
import { WorkflowService } from "src/app/services/workflow.service";
import { SupplierModelForSaveDraft } from "src/app/interfaces/supplierModelForSaveDraft";
import { LaunchDarklyService } from "src/app/services/launch-darkly.service";

type EditMode = "create" | "edit" | "view";

@Injectable({
  providedIn: "root",
})
export class OnBoardingService {
  supplierCode = "0";
  supplierModel: SupplierModel;
  supplierSteps: Array<WorkFlowTransactionModel> = [];
  current_step: number;

  riskAssessment: any;

  requestorChangeRequestList: any[];

  requestorCurrentChangeRequest: any;

  changeRequestList: any;
  mode: EditMode = "create";

  openChangeRequestItem: BuyerChangeRequestDTO;

  supplierCountryCode: string;

  profileStage: string;

  // Delete For BUG 521142
  // selectMultiDropdownMappingChanged = new EventEmitter()

  stepChangeEvent = new EventEmitter();

  get requestor_step() {
    return this.supplierModel.Mapping.requestor.step;
  }
  set requestor_step(step) {
    this.supplierModel.Mapping.requestor.step = step;
  }

  get spendCategoryGroupValue(): boolean {
    if (this.supplierModel.RequestForm.RequestorDetailsInfo.IndependentContractor == "Y") {
      SpendCategoryGroup['5002'] = false
      SpendCategoryGroup['5021'] = false
    } else {
      SpendCategoryGroup['5002'] = true
      SpendCategoryGroup['5021'] = true
    }
    return SpendCategoryGroup[
      this.supplierModel.RequestForm.RequestorDetailsInfo.SpendCategoryGroup
    ];
  }

  get isBIApplicable(): boolean {
    if(Object.entries(this.supplierModel.RequestForm.RiskAssessmentInfo).length > 0){
      if (this.supplierModel.RequestForm.RiskAssessmentInfo.BI_BDA_1 === "10") {
        return false;
      } else {
        return true;
      }
    }else{
      return false;
    }    
  }
  supplier: SupplierProfile = {};
  onBoardingInfo: OnBoardingRequestInfo = {};
  isNewVersion:boolean=true;
  userTasks: Array<Task> = []; // current login user's active task
  setupActiveTasks: Array<Task> = [];
  migrateActiveTasks: Array<Task> = [];
  buyerChangeActiveTasks: Array<Task> = [];
  supplierChangeActiveTasks: Array<Task> = [];
  constructor(
    private router: Router,
    private authService: AuthService,
    private supplierService: SupplierService,
    private buyerService: BuyerService,
    private workflowService: WorkflowService,
    private dashboardService: DashBoardService,
    private route: ActivatedRoute,
    private launchDarklyService:LaunchDarklyService
  ) {
    this.launchDarklyService.ldChange.subscribe(any => {
      console.log("Trigger refresh data")
      this.refreshToggle()
  })
  }
  refreshToggle():void{
    this.isNewVersion=this.launchDarklyService.getToggle(LaunchDarklyService.LD_ISNEWVERSION)==="true"
  }
  async initSupplierRequestForm() {
    this.supplierModel.Mapping = createSupplierMapping(this.supplierCode);
  }

  async loadSupplierRequestForm() {
    let data: any;
    if(this.isNewVersion) {
        data = await this.buyerService.queryForNewRequestForm(
          this.supplierCode
        );
    } else {
       data = await this.buyerService.queryRequestForm(
        this.supplierCode
      );
    }
   
    if (data && data.isSuccess) {
      this.supplierModel = data.data;
    }
  }
  
  async loadNewSupplierRequestForm() {
    try
    {
    let data: any;
    data = await this.buyerService.getSupplierSteps(this.supplierCode);
    if (data && data.isSuccess) {
      this.supplierSteps = data.data;
    }
  }
  catch(err)
  {
    console.error(err);
  }
  }

  async loadChangeRequestList(): Promise<BuyerChangeRequestDTO[]> {
    const data: any = await this.buyerService.getChangeRequestList(
      this.supplierCode
    );
    if (data.isSuccess) {
      return data.data;
    }
  }

  async loadSupplierProfile() {
    let data: any;
    if (this.isNewVersion) {
      data = await this.supplierService.queryNewSupplierProfile(
        this.supplierCode
      );
    } else {
      data = await this.supplierService.querySupplierProfile(
        this.supplierCode
        
      );
    }
    if (data.isSuccess && !!data.data.SupplierProfile) {
      this.supplierModel.SupplierProfile = data.data.SupplierProfile;
      this.supplierModel.Mapping = data.data.Mapping;
    }
  }
  
  async isInitBuyerProfile() {
    return !!this.supplierModel;
  }
  // async saveSupplierRequestForm() {
  //   this.supplierModel.Mapping.requestor.updatedBy = this.authService.passport.profile.email
  //   await this.buyerService.saveRequestForm(this.supplierModel)
  // }

  // workflow save form
  // request form: step 1 ~ 3 page save function
  async saveDraft() {
    this.supplierModel.Mapping.requestor.updatedBy =
      this.authService.passport.profile.email;
    const data: any = await this.buyerService.saveRequestFormDraft(
      this.supplierModel
    );
    if (data.isSuccess) {
      // console.log(" data.data.Mapping", data.data.Mapping);
      this.supplierModel.Mapping = data.data.Mapping;
      this.supplierModel.RequestForm = data.data.RequestForm;
    }
  }

    async saveDraftForRequestorDetails(model:SupplierModelForSaveDraft) {
    model.Mapping.requestor.updatedBy =
      this.authService.passport.profile.email;
    const data: any = await this.buyerService.saveRequestorDetails(
      model
    );
    
  }

  async saveDraftForRequestFormInfo(model:SupplierModelForSaveDraft) {
    model.Mapping.requestor.updatedBy =
      this.authService.passport.profile.email;
    const data: any = await this.buyerService.saveRequestFormInfo(
      model
    );
    
  }

  // request form: step 4 page submit and execute supplier stepup process's request_draft task
  async submitDraft() {
    this.supplierModel.Mapping.requestor.updatedBy =
      this.authService.passport.profile.email;
    return await this.buyerService.submitRequestFormDraft(this.supplierModel);
  }

  async submitDraftEnhanced(model:SupplierModelForSaveDraft) {
    model.Mapping.requestor.updatedBy =
      this.authService.passport.profile.email;
    return await this.buyerService.submitRequestFormDraftEnhanced(model);
  }

  // all sst reject to buyer task
  async resubmitForm() {
    this.supplierModel.Mapping.requestor.updatedBy =
      this.authService.passport.profile.email;
    await this.buyerService.resubmitRequestForm(this.supplierModel);
  }

  async resubmitFormEnhanced(model:SupplierModelForSaveDraft) {
    model.Mapping.requestor.updatedBy =
      this.authService.passport.profile.email;
    await this.buyerService.resubmitRequestFormEnhanced(model);
  }

  // all buyer change request process: save request form
  async changeRequestForm() {
    this.supplierModel.Mapping.requestor.updatedBy =
      this.authService.passport.profile.email;
    await this.buyerService.changeRequestForm(this.supplierModel);
  }

  // request form terminate
  async requestFormTerminate() {
    this.supplierModel.Mapping.requestor.updatedBy =
      this.authService.passport.profile.email;
    const result: any = await this.buyerService.requestFormTerminate(
      this.supplierModel
    );
    if (result.isSuccess) {
      return result;
    }
  }

  async checkEmail(email: string, tsmId: string) {
    const result: any = await this.buyerService.checkEmail(email, tsmId);
    if (result.isSuccess) {
      return result.data;
    }
  }

  async checkCompanyName(companyName: string) {
    const result: any = await this.buyerService.checkCompanyName(companyName);
    if (result.isSuccess) {
      if (this.TsmId()) {
        return true;
      }
      return result.data;
    }
  }

  async checkVersionOfHiperos() {
    const result: any = await this.buyerService.checkVersionOfHiperos(
      this.supplierCode
    );
    if (result.isSuccess) {
      return result.data;
    }
  }

  async loadRiskAssessment() {
    const data: any = await this.buyerService.queryRiskAssessment(
      this.supplierCode
    );
    if (data.isSuccess) {
      this.riskAssessment = data.data;
    }
  }

  async navigateTo(step, supplierCode) {
    if (step <= this.requestor_step) {
      this.router.navigate([`buyer/onboarding/${supplierCode}/step${step}`], {replaceUrl: true});
    }
  }

  backTo() {
    if (this.current_step > 1) {
      this.router.navigate([
        `buyer/onboarding/${this.supplierModel.Mapping.supplierCode}/step${
          this.current_step - 1
        }`,
      ], {replaceUrl: true});
    } else {
      if (this.dashboardService.isTsmEnterOnboarding) {
        this.router.navigate([
          `buyer/search/${this.dashboardService.tsmKey1}/${this.dashboardService.tsmKey2}`,
        ], {replaceUrl: true});
      } else {
        this.router.navigate(["buyer"], {replaceUrl: true});
      }
    }
  }

  // check resubmit button is enable
  checkResubmit() {
    // reject to buyer
    if (
      this.getUserTaskByKey(
        ProcessNames.generic_supplierSetupSubProcess,
        SetupProcess.SST_rejectToBuyer
      ) ||
      (this.checkTaskIsComplete(
        ProcessNames.generic_supplierSetup,
        SetupProcess.requestor_draft
      ) &&
        !this.checkTaskIsComplete(
          ProcessNames.generic_supplierSetup,
          SetupProcess.supplier_accepted
        ))
    ) {
      return true;
    } else {
      return false;
    }
  }

  // check is terminate

  checkIsTerminate() {
    // request draft
    if (
      this.getUserTaskByKey(
        ProcessNames.generic_supplierSetup,
        SetupProcess.requestor_draft
      )
    ) {
      return true;
    } else {
      return false;
    }
  }

  checkRequesterIsCompleteForTsm() {
    // request draft
    if (
      this.checkTaskIsComplete(
        ProcessNames.generic_supplierSetup,
        SetupProcess.profile_draft
      )
    ) {
      return true;
    } else {
      return false;
    }
  }

  checkIsSaveContitue() {
    // request draft completed no show save button
    if (window.location.href.includes("buyer/onboarding/0/step1")) {
      return true;
    }
    if (
      this.getUserTaskByKey(
        ProcessNames.generic_supplierSetup,
        SetupProcess.requestor_draft
      )
    ) {
      return true;
    } else if (this.getUserTaskByTaskKey(AllTaskKey.SST_rejectToBuyer)) {
      return true;
    } else if (
      this.checkTaskIsComplete(
        ProcessNames.generic_supplierSetup,
        SetupProcess.requestor_draft
      ) &&
      !this.checkTaskIsComplete(
        ProcessNames.generic_supplierSetup,
        SetupProcess.supplier_accepted
      )
    ) {
      return true;
    } else {
      return false;
    }
  }

  // load all tasks
  async loadUserTasks() {
    this.userTasks = await this.workflowService.loadUserTasks(
      this.authService.passport.buyer.RoleCode,
      this.supplierModel.Mapping.supplierCode
    );
  }

  // get active task by processKey and taskKey
  getUserTaskByKey(processKey: string, taskKey: string): Task {
    return this.workflowService.getTaskByKey(
      processKey,
      taskKey,
      this.userTasks
    );
  }

  // get active task by task key
  getUserTaskByTaskKey(taskKey: string): Task {
    return this.workflowService.getTaskByTaskKey(taskKey, this.userTasks);
  }

  // Does not include AP sub process
  checkTaskIsComplete(processKey: string, taskKey: string) {
    return this.workflowService.checkTaskIsComplete(
      processKey,
      taskKey,
      this.supplierModel.Mapping.historyTasks
    );
  }

  // AP sub process
  // check ap approve
  checkAPTaskIsComplete(processKey: string, taskKey: string) {
    return this.workflowService.checkAPTaskIsComplete(
      processKey,
      taskKey,
      this.supplierModel.Mapping.historyTasks
    );
  }

  // check ap reject
  checkAPTaskIsReject(processKey: string, taskKey: string) {
    return this.workflowService.checkAPTaskIsReject(
      processKey,
      taskKey,
      this.supplierModel.Mapping.historyTasks
    );
  }

  // get execute task
  getExecuteTask(
    processKey: string,
    taskKey: string,
    variables: any = {}
  ): SupplierModel {
    this.supplierModel.Mapping.executeTask =
      this.workflowService.getExecuteTask(processKey, taskKey, variables);
    return this.supplierModel;
  }

  isMET() {
    return (
      this.supplierModel.RequestForm.RequestorDetailsInfo
        .SupplierGeographical === "24"
    );
  }

  isFromTsm() {
    const roles = ["requestor"];
    if (this.supplierModel) {
      return (
        !!this.supplierModel.Mapping.TsmId &&
        roles.includes(this.authService.passport.buyer.RoleCode)
      );
    } else {
      return false;
    }
  }
  isFromTsmStep5() {
    if (this.supplierModel) {
      return !!this.supplierModel.Mapping.TsmId;
    } else {
      return false;
    }
  }
  TsmId() {
    if (this.supplierModel) {
      return this.supplierModel.Mapping.TsmId;
    }
  }
}
