import { Component, OnInit, Input, Output } from "@angular/core";
import { Router } from "@angular/router";
import { SupplierModel } from "src/app/interfaces/supplierModel";
import {
  SupplierStatus,
  SupplierPendingStatus,
  AP_ReviewStatus,
  InvitedStatusMapping,
} from "src/app/interfaces/mapping";
import { CommonService } from "src/app/services/common.service";
import { WorkflowService } from "src/app/services/workflow.service";
import {
  Task,
  AllTaskKey,
  ProcessNames,
  SetupProcess,
  HistoryTask,
  getTaskCompletedName,
  AllTaskCompletedName,
} from "src/app/interfaces/workflow";
import { AuthService } from "src/app/services/auth/auth.service";
import { OnBoardingService } from "src/app/modules/buyer/services/onboarding.service";
import { HoldOnService } from "src/app/services/HoldOn";
import { DictionaryService } from "src/app/services/dictionary.service";
import { isPplusUser } from "src/app/modules/buyer/services/verifyProcurementPlus";
import { RoleName } from "src/app/interfaces/buyer";
import { BuyerService } from "src/app/services/buyer.service";

enum MigratedTag {
  newCreation = "Created in Supplier Hub",
  migrationWithoutInvited = "Migrated and Inactive",
  migrationWithInvitationSent = "Migrated and Invitation Sent",
  migrationWithInvited = "Migrated and Active",
}
@Component({
  selector: "app-supplier-card",
  templateUrl: "./supplier-card.component.html",
  styleUrls: ["./supplier-card.component.sass"],
})
export class SupplierCardComponent implements OnInit {
  CompanyCode: any;
  SpendCategoryGroup: any;
  CompanyCodeC: any;
  SAP: any;
  SAPList = [];
  SpendCategoryGroupC: any;
  companyCodeList = [];
  companyCodeListData: any;
  checkSupplier: any;
  isShowSAPTooltip = false;
  isShowCompanyCodeTooltip = false;
  isShowOnHold = false;
  @Input() supplier: SupplierModel;
  @Input() onboardingPage: boolean = false;
  supplierStatus = SupplierStatus;
  get displayStatus(): string {
    if (this.supplier.Mapping.status === SupplierStatus.onboarded) {
      return SupplierStatus.onboarded;
    } else if (this.supplier.Mapping.status === SupplierStatus.terminated) {
      return SupplierStatus.terminated;
    } else if (this.supplier.Mapping.status === SupplierStatus.deactivated) {
      return SupplierStatus.deactivated;
    } else {
      let historyTasks = this.supplier.Mapping.historyTasks || [];
      historyTasks = historyTasks.filter(
        (task: HistoryTask) =>
          task.name !== AllTaskKey.GDPR &&
          task.name !== AllTaskKey.BI &&
          task.name !== AllTaskKey.SST_rejectToBuyer &&
          task.name !== AllTaskKey.SST_rejectToSupplier
      );
      historyTasks.sort((a, b) => {
        if (a.id && b.id) {
          return  a.id - b.id
        }
      })
      const historyTask = historyTasks[historyTasks.length - 1];
      if (historyTask) {
        const taskName = historyTask.name;
        if (taskName === AllTaskKey.QC_review) {
          return AllTaskCompletedName.QC_review;
        } else if (taskName === AllTaskKey.SST_review) {
          return AllTaskCompletedName.SST_review;
        } else if (taskName === AllTaskKey.profile_draft) {
          return AllTaskCompletedName.profile_draft;
        } else if (taskName === AllTaskKey.requestor_draft) {
          return AllTaskCompletedName.requestor_draft;
        } else if (taskName === AllTaskKey.supplier_accepted) {
          return AllTaskCompletedName.supplier_accepted;
        } else {
          return "";
        }
      }
      return "Requestor Draft InComplete";
    }
  }
  get BRN(): string {
    // When 'India' has been selected in the Geographical Unit for this Supplier (RQ7) field
    if (this.supplier.Mapping.geographicalUnit === "21") {
      //  When 'India' has been selected in the Supplier Registered Country/Location (2.01) field
      if (this.supplier.SupplierProfile.SupplierOrganization.CompanyPANNumber) {
        return this.supplier.SupplierProfile.SupplierOrganization
          .CompanyPANNumber;
      }

      return "";
    }

    // If not selected India in the Geographical Unit
    return this.supplier.Mapping.searchTerm.BRN;
  }
  get migratedTagValue(): string {
    if (this.supplier.Mapping.IsMigrated === false) {
      return MigratedTag.newCreation;
    } else {
      if (this.supplier.Mapping.IsInvited === false) {
        return MigratedTag.migrationWithoutInvited;
      } else {
        if (
          this.supplier.Mapping.InvitedStatus === InvitedStatusMapping.invited
        ) {
          return MigratedTag.migrationWithInvitationSent;
        } else if (
          this.supplier.Mapping.InvitedStatus === InvitedStatusMapping.accepted
        ) {
          return MigratedTag.migrationWithInvited;
        }
      }
    }
  }
  constructor(
    private router: Router,
    private dictionaryService: DictionaryService,
    private workflowService: WorkflowService,
    private auth: AuthService,
    private onboardingService: OnBoardingService,
    private holdService: HoldOnService,
    private buyerService: BuyerService
  ) {}

  async ngOnInit() {
    this.CompanyCodeC =
      this.supplier.RequestForm.RequestorDetailsInfo.CompanyCode;
    this.SpendCategoryGroupC =
      this.supplier.RequestForm.RequestorDetailsInfo.SpendCategoryGroup;
    this.SAPList = this.supplier.Mapping.searchTerm.thirdPartySAP
      ? this.supplier.Mapping.searchTerm.SAP.concat(
          this.supplier.Mapping.searchTerm.thirdPartySAP
        )
      : this.supplier.Mapping.searchTerm.SAP ;
    if (
      this.supplier.Mapping.gstSapItem &&
      this.supplier.Mapping.gstSapItem.length > 0
    ) {
      const gstaspids = this.supplier.Mapping.gstSapItem.map((a) => a.sapId);
      this.SAPList = this.SAPList.concat(gstaspids);
    }
    if (
      this.supplier.Mapping.activeOnhold
    ) {
      this.isShowOnHold = true;
    }
    const dicts = await this.dictionaryService.getDictionary(
      "requestor-details"
    );
    if (this.CompanyCodeC) {
      this.CompanyCodeC.forEach((v, i) => {
        dicts.forEach((dict) => {
          if (dict.Code === "CompanyCode") {
            dict.Items.forEach((item) => {
              if (
                this.supplier.RequestForm.RequestorDetailsInfo.SupplierCountry.includes(
                  item.Code
                )
              ) {
                item.Items.forEach((subItem) => {
                  if (subItem.Code === v) {
                    this.companyCodeList.push(subItem.Text);
                  }
                });
              }
            });
          }
        });
        this.companyCodeListData = this.companyCodeList.join(" , ");
        // eslint-disable-next-line max-len
        this.SpendCategoryGroup = dicts
          .filter((dict) => dict.Code === "SpendCategoryGroup")[0]
          .Items.filter((item) => item.Code === this.SpendCategoryGroupC)[0]
          ? dicts
              .filter((dict) => dict.Code === "SpendCategoryGroup")[0]
              .Items.filter((item) => item.Code === this.SpendCategoryGroupC)[0]
              .Text
          : "";
      });
    }
  }

  companyCodeMouseover() {
    if (this.companyCodeList.length > 1) {
      this.isShowCompanyCodeTooltip = true;
    }
  }
  sapMouseover() {
    if (this.SAPList !== null) {
      this.SAP = this.SAPList.join(" , ");
      if (this.SAPList.length > 2) {
        this.isShowSAPTooltip = true;
      }
    }
  }
  
  async nav(supplierCode) {
    this.checkSupplier = await this.buyerService.getExistingSupplier(supplierCode);
    let data:any = {};
    data = await this.buyerService.getSupplierSteps(supplierCode);
    if(isPplusUser(this.auth.passport.buyer.RoleCode) && this.onboardingPage === false && this.checkSupplier?.data === true){
      if (data) {
        for(let status of data.data){
          if(status.StepName === 'Procurement Plus Review' && (status.Status === 'rejected' || status.Status === 'completed')){
            this.router.navigate([`buyer/onboarding/process/${supplierCode}`]);
            return
          }
        }
      }
      this.router.navigate([`buyer/onboarding/${supplierCode}/step1`]);
    }
    else{
      const userTasks = await this.workflowService.loadUserTasks(
        this.auth.passport.buyer.RoleCode,
        supplierCode
      );
      if (
        this.workflowService.getTaskByKey(
          ProcessNames.generic_supplierSetup,
          SetupProcess.requestor_draft,
          userTasks
        )
      ) {
        this.router.navigate([`buyer/onboarding/${supplierCode}/step1`]);
      } else {
        this.router.navigate([`buyer/onboarding/process/${supplierCode}`]);
      }
    }
  }

  get BI_BDA_Value() {
    if (this.supplier.RequestForm.RiskAssessmentInfo.BI_BDA_1) {
      const BI_BDA_Value =
        this.supplier.RequestForm.RiskAssessmentInfo.BI_BDA_1;
      if (BI_BDA_Value === "11" || BI_BDA_Value === "12") {
        return "Yes";
      }
      return "No";
    } else {
      return "TBD";
    }
  }
  gotobackonkeydown(event, suppliercode) {
    if(event.keyCode == 13){
      this.nav(suppliercode)
    }
  }
}
